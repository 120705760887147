import axios from './index'

import FormValidationError from '@/errors/FormValidationError'
import NotAllowedError from '@/errors/NotAllowedError'

export async function fetchAcademicNetwork() {
  const response = await axios.get('api/publications/academic-network')
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export async function fetchAcademicNetworks({ personId }) {
  const requestUrl = `api/publications/${personId}/academic-networks`
  const response = await axios.get(requestUrl)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export async function findPublicationCategories() {
  const response = await axios.get('api/publications/categories')
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export async function findMemberDivisions({ userId }) {
  const requestUrl = `api/people/${userId}/divisions`
  const response = await axios.get(requestUrl)
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const createPublication = async (data) => {
  const response = await axios.post(`api/publications/add-custom-article`, data)

  if (response.status === 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else if (response.status === 403) {
    throw new NotAllowedError(response.data)
  }
  throw new Error('Unexpected status code returned')
}

export async function fetchInvestigationLines() {
  const response = await axios.get('api/publications/investigation-lines')
  if (response.status === 200) {
    return response.data
  }
  throw new Error('Unexpected status code returned')
}

export const editPublication = async (publication_id, data) => {
  data['id'] = publication_id
  const response = await axios.post(`api/publications/edit-article`, data)

  if (response.status === 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else if (response.status === 403) {
    throw new NotAllowedError(response.data)
  }
  throw new Error('Unexpected status code returned')
}

export const deletePublication = async (publication_id) => {
  const response = await axios.delete(`api/publications/delete-article/${publication_id}`)

  if (response.status === 204) {
    return response.data
  } else if (response.status === 400 || response.status === 422) {
    throw new FormValidationError(response.data)
  } else if (response.status === 403) {
    throw new NotAllowedError(response.data)
  }
  throw new Error('Unexpected status code returned')
}
