<template>
  <div>
    <b-taginput
      open-on-focus
      clearable
      v-model="val"
      field="name"
      :allow-new="true"
      :placeholder="$t('multiplePersonSelector.placeholder')"
      @input="setInput"
      :id="id"
      :disabled="disabled"
      :closable="!disabled"
      aria-label="external-authors"
    >
    </b-taginput>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: false
    },
    id: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    selectedExternalAuthors: {
      required: false,
      type: Array
    }
  },
  data() {
    return {
      val: [],
      delayedFetchTimeout: null
    }
  },
  methods: {
    setInput(input) {
      this.$emit(
        'input',
        input.map(function (element) {
          return element
        })
      )
    }
  },
  async mounted() {
    if (typeof this.value === 'number') {
      this.val = this.suggestions.filter((person) => person.id === this.value)
    } else if (typeof this.value === 'string' && this.value.length > 1) {
      this.val = [this.value]
    }

    if (this.selectedExternalAuthors != null) {
      let selection = this.selectedExternalAuthors.map((person) => {
        return { name: person.name, id: person.id }
      })
      this.val = selection
    }
  }
}
</script>
