<template>
  <div class="container">
    <h1 class="title">
      {{ $t('publicationCreate.title') }}
    </h1>

    <v-form
      :onSubmit="sendForm"
      :onSubmitSuccess="onSubmitSuccess"
      :onSubmitError="onSubmitError"
      :process-form="changeDateFormat"
      ref="publicationCreateForm"
    >
      <template v-slot="{ form }">
        <message-success v-if="showSuccess" :detail="$t('signUpForm.successMsg')" />
        <message-error v-if="form.errors.length > 0" :detail="Object.values(form.errors).flat()" />
        <message-error v-if="form.errors.__root__" :detail="form.errors.__root__" />
        <div class="columns is-tablet">
          <div class="column is-7">
            <b-field
              :label="$t('publicationCreate.publicationTitle')"
              id="title-input"
              :message="form.errors.title"
              :type="form.types.title"
            >
              <b-input v-model="form.values.title" aria-label="title" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.internalAuthors')"
              :message="form.errors.internal_authors"
              :type="form.types.internal_authors"
            >
              <multiple-person-selector v-model="form.values.internal_authors" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.externalAuthors')"
              :message="form.errors.external_authors"
              :type="form.types.external_authors"
            >
              <multiple-external-person-selector
                v-model="form.values.external_authors"
                @newMemberAdded="newMember = true"
              />
            </b-field>
            <b-field
              :label="$t('publicationCreate.category')"
              id="category-select"
              :message="form.errors.category_id"
              :type="form.types.category_id"
            >
              <publication-category-selector v-model="form.values.category_id" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.investigationLine')"
              id="investigation-line-select"
              :message="form.errors.investigation_line_id"
              :type="form.types.investigation_line_id"
            >
              <investigation-line-selector v-model="form.values.investigation_line_id" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.publicationDate')"
              :message="form.errors.publication_date"
              :type="form.types.publication_date"
            >
              <b-datepicker
                v-model="form.values.publication_date"
                :placeholder="$t('form.clickToSelect')"
                :value="
                  form.values.publication_date
                    ? dateWithoutOffset(form.values.publication_date)
                    : null
                "
                @input="
                  (value) => {
                    form.values.publication_date = value
                  }
                "
                aria-label="publication-date"
              >
              </b-datepicker>
            </b-field>
            <b-field
              :label="$t('publicationCreate.source')"
              id="source-input"
              :message="form.errors.source_name"
              :type="form.types.source_name"
            >
              <b-input v-model="form.values.source_name" aria-label="source_name" />
            </b-field>
            <div class="columns">
              <div class="column is-4">
                <b-field
                  :label="$t('publicationCreate.volume')"
                  id="volume-input"
                  :message="form.errors.source_volume"
                  :type="form.types.source_volume"
                >
                  <b-input v-model="form.values.source_volume" aria-label="source_volume" />
                </b-field>
              </div>
              <div class="column is-4">
                <b-field
                  :label="$t('publicationCreate.number')"
                  id="number-input"
                  :message="form.errors.source_number"
                  :type="form.types.source_number"
                >
                  <b-input v-model="form.values.source_number" aria-label="source_number" />
                </b-field>
              </div>
              <div class="column is-4">
                <b-field
                  :label="$t('publicationCreate.page')"
                  id="page-input"
                  :message="form.errors.inital_page"
                  :type="form.types.inital_page"
                >
                  <b-input v-model="form.values.inital_page" aria-label="inital_page" />
                </b-field>
              </div>
            </div>
            <b-field
              :label="$t('publicationCreate.doi')"
              id="doi-input"
              :message="form.errors.doi"
              :type="form.types.doi"
            >
              <b-input v-model="form.values.doi" aria-label="doi" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.issn')"
              id="issn-input"
              :message="form.errors.issn"
              :type="form.types.issn"
            >
              <b-input v-model="form.values.issn" aria-label="issn" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.quartile')"
              id="quartile-input"
              :message="form.errors.quartile"
              :type="form.types.quartile"
            >
              <b-input v-model="form.values.quartile" aria-label="quartile" />
            </b-field>
            <b-field
              id="formal_network-input"
              :message="form.errors.formal_network"
              :type="form.types.formal_network"
            >
              <b-checkbox v-model="form.values.formal_network" aria-label="formal_network">
                {{ $t('publicationCreate.formal_network') }}
              </b-checkbox>
            </b-field>
            <b-field
              id="conference_presentation-input"
              :message="form.errors.conference_presentation"
              :type="form.types.conference_presentation"
            >
              <b-checkbox
                v-model="form.values.conference_presentation"
                aria-label="conference_presentation"
              >
                {{ $t('publicationCreate.conference_presentation') }}
              </b-checkbox>
            </b-field>
            <div class="buttons is-right">
              <b-button type="is-beige-light" @click="$emit('publicationSaved')">{{
                $t('personEditProfile.cancelButton')
              }}</b-button>
              <b-button type="is-primary" native-type="submit">
                {{ $t('publicationCreate.saveButton') }}
              </b-button>
            </div>
          </div>
          <div class="column is-5">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
                  </div>
                  <div class="media-content">
                    {{ $t('publicationCreate.infoMessage') }}
                    <b>{{ $t('publicationCreate.infoHighlight') }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MultiplePersonSelector from '@/components/MultiplePersonSelector'
import MultipleExternalPersonSelector from '@/components/MultipleExternalPersonSelector'
import PublicationCategorySelector from '@/components/PublicationCategorySelector'
import InvestigationLineSelector from '@/components/InvestigationLineSelector'
import VForm from '@/components/VForm'
import { createPublication } from '@/requests/publications'
import { dateWithoutOffset, toBackendFormat } from '@/utils/date-utils'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'PublicationCreate',
  components: {
    MultiplePersonSelector,
    MultipleExternalPersonSelector,
    PublicationCategorySelector,
    InvestigationLineSelector,
    VForm
  },

  methods: {
    dateWithoutOffset,
    async sendForm(form) {
      this.loading = this.$buefy.loading.open({
        container: this.$refs.publicationCreateForm.$el
      })
      try {
        await createPublication(form)
      } finally {
        this.loading.close()
      }
    },
    onSubmitSuccess() {
      this.loading.close()
      this.$buefy.toast.open({
        message: this.$t('publicationCreate.successMessage'),
        type: 'is-success'
      })
      this.$emit('publicationSaved')
      this.$emit('refetchUserPublications')
    },
    onSubmitError(error) {
      this.loading.close()
      this.$buefy.toast.open({
        message: error.message,
        type: 'is-danger'
      })
    },
    changeDateFormat(form) {
      form.publication_date = form.publication_date
        ? toBackendFormat(new Date(form.publication_date))
        : null
      return form
    }
  },
  data() {
    return {
      showSuccess: false,
      loading: null
    }
  },
  computed: {
    ...mapState(['person'])
  }
}
</script>
