<template>
  <div class="container">
    <h1 class="title">{{ $t('publicationEdit.title') }}</h1>
    <v-form
      :initialValue="publication"
      :onSubmit="sendForm"
      :onSubmitSuccess="onSubmitSuccess"
      :onSubmitError="onSubmitError"
      :process-form="changeDateFormat"
      ref="publicationEditForm"
    >
      <template v-slot="{ form }">
        <message-success v-if="showSuccess" :detail="$t('signUpForm.successMsg')" />
        <message-error v-if="form.errors.length > 0" :detail="Object.values(form.errors).flat()" />
        <message-error v-if="form.errors.__root__" :detail="form.errors.__root__" />
        <div class="columns is-tablet">
          <div class="column is-7">
            <b-field
              :label="$t('publicationCreate.publicationTitle')"
              id="title-input"
              :message="form.errors.title"
              :type="form.types.title"
            >
              <b-input v-model="form.values.title" aria-label="title" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.internalAuthors')"
              :message="form.errors.internal_authors"
              :type="form.types.internal_authors"
            >
              <multiple-person-selector
                v-model="form.values.internal_authors"
                :selectedInternalAuthors="selectedInternalAuthors"
              />
            </b-field>
            <b-field
              :label="$t('publicationCreate.externalAuthors')"
              :message="form.errors.external_authors"
              :type="form.types.external_authors"
            >
              <multiple-external-person-selector
                v-model="form.values.external_authors"
                :selectedExternalAuthors="publication.external_authors"
              />
            </b-field>
            <b-field
              :label="$t('publicationCreate.category')"
              id="category-select"
              :message="form.errors.category_id"
              :type="form.types.category_id"
            >
              <b-select placeholder="Selecciona una categoría" v-model="selectedCategoryId">
                <option
                  v-for="category in categories"
                  :value="category.id"
                  :key="category.id"
                  :selected="category.id == publication.category.id"
                >
                  {{ category.translation }}
                </option>
              </b-select>
            </b-field>
            <b-field
              :label="$t('publicationCreate.investigationLine')"
              id="investigation-line-select"
              :message="form.errors.investigation_line_id"
              :type="form.types.investigation_line_id"
            >
              <b-select
                placeholder="Selecciona una línea de investigación"
                v-model="selectedInvestigationLineId"
              >
                <option
                  v-for="investigation_line in investigation_lines"
                  :value="investigation_line.id"
                  :key="investigation_line.id"
                  :selected="investigation_line.id == publication.investigation_line.id"
                >
                  {{ investigation_line.name }}
                </option>
              </b-select>
            </b-field>
            <b-field
              :label="$t('publicationCreate.publicationDate')"
              :message="form.errors.publication_date"
              :type="form.types.publication_date"
            >
              <b-datepicker
                v-model="selectedDate"
                :placeholder="$t('form.clickToSelect')"
                :value="
                  form.values.publication_date
                    ? dateWithoutOffset(form.values.publication_date)
                    : null
                "
                @input="
                  (value) => {
                    form.values.publication_date = value
                  }
                "
                aria-label="publication-date"
              >
              </b-datepicker>
            </b-field>
            <b-field
              :label="$t('publicationCreate.source')"
              id="source-input"
              :message="form.errors.source_name"
              :type="form.types.source_name"
            >
              <b-input v-model="form.values.source_name" aria-label="source_name" />
            </b-field>
            <div class="columns">
              <div class="column is-4">
                <b-field
                  :label="$t('publicationCreate.volume')"
                  id="volume-input"
                  :message="form.errors.source_volume"
                  :type="form.types.source_volume"
                >
                  <b-input v-model="form.values.source_volume" aria-label="source_volume" />
                </b-field>
              </div>
              <div class="column is-4">
                <b-field
                  :label="$t('publicationCreate.number')"
                  id="number-input"
                  :message="form.errors.source_number"
                  :type="form.types.source_number"
                >
                  <b-input v-model="form.values.source_number" aria-label="source_number" />
                </b-field>
              </div>
              <div class="column is-4">
                <b-field
                  :label="$t('publicationCreate.page')"
                  id="page-input"
                  :message="form.errors.inital_page"
                  :type="form.types.inital_page"
                >
                  <b-input v-model="form.values.inital_page" aria-label="inital_page" />
                </b-field>
              </div>
            </div>
            <b-field
              :label="$t('publicationCreate.doi')"
              id="doi-input"
              :message="form.errors.doi"
              :type="form.types.doi"
            >
              <b-input v-model="form.values.doi" aria-label="doi" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.issn')"
              id="issn-input"
              :message="form.errors.issn"
              :type="form.types.issn"
            >
              <b-input v-model="form.values.issn" aria-label="issn" />
            </b-field>
            <b-field
              :label="$t('publicationCreate.quartile')"
              id="quartile-input"
              :message="form.errors.quartile"
              :type="form.types.quartile"
            >
              <b-input v-model="form.values.quartile" aria-label="quartile" />
            </b-field>
            <b-field
              id="formal_network-input"
              :message="form.errors.formal_network"
              :type="form.types.formal_network"
            >
              <b-checkbox v-model="form.values.formal_network" aria-label="formal_network">
                {{ $t('publicationCreate.formal_network') }}
              </b-checkbox>
            </b-field>
            <b-field
              id="conference_presentation-input"
              :message="form.errors.conference_presentation"
              :type="form.types.conference_presentation"
            >
              <b-checkbox
                v-model="form.values.conference_presentation"
                aria-label="conference_presentation"
              >
                {{ $t('publicationCreate.conference_presentation') }}
              </b-checkbox>
            </b-field>
            <div class="buttons is-right">
              <b-button type="is-beige-light" @click="$emit('publicationSaved')">{{
                $t('personEditProfile.cancelButton')
              }}</b-button>
              <b-button type="is-primary" native-type="submit">
                {{ $t('publicationEdit.saveButton') }}
              </b-button>
            </div>
          </div>
          <div class="column is-5">
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-left">
                    <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
                  </div>
                  <div class="media-content">
                    {{ $t('publicationCreate.infoMessage') }}
                    <b>{{ $t('publicationCreate.infoHighlight') }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-form>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MultiplePersonSelector from '@/components/MultiplePersonSelector'
import MultipleExternalPersonSelector from '@/components/MultipleExternalPersonSelector'
import VForm from '@/components/VForm'
import { editPublication } from '@/requests/publications'
import { dateWithoutOffset, toBackendFormat } from '@/utils/date-utils'
import { findPublicationCategories, fetchInvestigationLines } from '@/requests/publications'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'PublicationEdit',
  props: {
    publication: {
      type: Object,
      required: true
    }
  },
  components: {
    MultiplePersonSelector,
    MultipleExternalPersonSelector,
    VForm
  },
  methods: {
    dateWithoutOffset,
    async sendForm(form) {
      // TODO: modificar el selector para usar un único modelo en cada componente (no usar campos extra)
      // ver si es posible usar solo v-model en lugar de props

      this.loading = this.$buefy.loading.open({
        container: this.$refs.publicationEditForm.$el
      })
      form['category_id'] = this.selectedCategoryId
      form['investigation_line_id'] = this.selectedInvestigationLineId

      var internal_authors = form['internal_authors']
      var internal_authors_list = []
      for (let key in internal_authors) {
        let author = internal_authors[key]
        internal_authors_list.push(
          Object.prototype.hasOwnProperty.call(author, 'id') ? author['id'] : author
        )
      }
      form['internal_authors'] = internal_authors_list

      var external_authors = form['external_authors']
      var external_authors_list = []
      for (let key in external_authors) {
        let author = external_authors[key]
        external_authors_list.push(
          Object.prototype.hasOwnProperty.call(author, 'name') ? author['name'] : author
        )
      }
      form['external_authors'] = external_authors_list

      delete form['category']
      delete form['investigation_line']
      delete form['authors']
      delete form['publisher']

      try {
        await editPublication(this.publication.id, form)
      } finally {
        this.loading.close()
      }
    },
    onSubmitSuccess() {
      this.loading.close()
      this.$buefy.toast.open({
        message: this.$t('publicationEdit.successMessage'),
        type: 'is-success'
      })
      this.$emit('publicationSaved')
      this.$emit('refetchUserPublications')
    },
    onSubmitError(error) {
      this.loading.close()
      this.$buefy.toast.open({
        message: error.message,
        type: 'is-danger'
      })
    },
    changeDateFormat(form) {
      form.publication_date = form.publication_date
        ? toBackendFormat(new Date(form.publication_date))
        : null
      return form
    }
  },
  async mounted() {
    this.selectedDate = new Date(this.publication.publication_date)
    this.selectedCategoryId = this.publication.category.id
    this.selectedInvestigationLineId = this.publication.investigation_line.id
    this.selectedInternalAuthors = this.publication.internal_authors
    this.selectedExternalAuthors = this.publication.external_authors
    this.categories = await findPublicationCategories()
    this.investigation_lines = await fetchInvestigationLines()
  },
  data() {
    return {
      showSuccess: false,
      loading: null,
      selectedDate: null,
      selectedCategoryId: null,
      selectedInvestigationLineId: null,
      selectedInternalAuthors: [],
      selectedExternalAuthors: [],
      categories: [],
      investigation_lines: []
    }
  },
  computed: {
    ...mapState(['person'])
  }
}
</script>
