<template>
  <div class="container margin">
    <b-loading :is-full-page="true" :active="isLoading" :can-cancel="true"></b-loading>
    <div class="columns is-centered is-mobile">
      <div class="column is-narrow has-text-centered is-flex is-flex-direction-column">
        <figure
          class="image is-128x128 is-align-self-center"
          style="margin-inline-end: 0; padding: 0"
        >
          <img class="is-rounded" style="margin: 0 auto" :src="imageUrl" />
        </figure>
        <div class="is-align-self-flex-end">
          <form enctype="multipart/form-data" novalidate>
            <input
              id="file"
              type="file"
              multiple
              @change="filesChange"
              accept="image/*"
              class="input-file"
              style="visibility: hidden"
            />
            <label for="file" class="btn"
              ><b-icon
                class="hover-imfd is-align-self-flex-end"
                icon="camera-plus-outline"
                style="margin-right: 10vh"
              ></b-icon
            ></label>
          </form>
        </div>
        <div class="has-text-centered name-label">
          {{ `${person.first_name} ${person.last_name}` }}
        </div>
        <div class="has-text-centered position-label">{{ person.active_membership }}</div>
      </div>
    </div>

    <b-tabs position="is-centered" v-model="activeTab">
      <b-tab-item :label="$t('views.personProfile.personalInformation')" value="personalInfo">
        <div class="columns is-centered">
          <div class="column is-6 is-12-mobile" v-if="!editProfile">
            <person-card :person="person" />
          </div>
          <div class="column is-6 is-12-mobile" v-else>
            <person-edit-profile :person="person" @closeEditProfile="editProfile = false" />
          </div>
        </div>
      </b-tab-item>

      <b-tab-item :label="$t('views.personProfile.associatedDivisions')" value="divisions">
        <v-promised :promise="memberDivisions">
          <template #pending>
            <b-loading :can-cancel="false"></b-loading>
          </template>

          <template v-slot="data">
            <div class="columns is-multiline" v-if="data.length > 0">
              <div
                class="column is-4"
                v-for="memberDivision of data"
                :key="`division${memberDivision.id}`"
              >
                <division-card
                  :division="memberDivision"
                  :role="memberDivision.role_type || person.active_membership"
                />
              </div>
            </div>

            <div class="is-flex is-flex-direction-column mt-4 mb-4" v-else>
              <div class="is-align-self-center">
                <img id="no-division" src="@/assets/images/sin_divisiones.png" />
              </div>
              <div class="is-align-self-center">
                <p v-html="$t('divisionDetailBody.noSubdivisions')"></p>
              </div>
            </div>
          </template>

          <template #rejected="error">
            <v-fail :error="error" />
          </template>
        </v-promised>
      </b-tab-item>
      <b-tab-item :label="$t('views.personProfile.associatedProjects')" value="projects">
        <v-promised :promise="memberProjects">
          <template #pending>
            <b-loading :can-cancel="false"></b-loading>
          </template>

          <template v-slot="data">
            <div class="columns is-multiline" v-if="data.length > 0">
              <div
                class="column is-4"
                v-for="memberProject of data"
                :key="`project${memberProject.id}`"
              >
                <division-card :division="memberProject" :role="memberProject.role_type" />
              </div>
            </div>

            <div class="is-flex is-flex-direction-column mt-4 mb-4" v-else>
              <div class="is-align-self-center">
                <img id="no-division" src="@/assets/images/sin_proyectos.png" />
              </div>
              <div class="is-align-self-center">
                <p v-html="$t('divisionDetailBody.noProjects')"></p>
              </div>
            </div>
          </template>

          <template #rejected="error">
            <v-fail :error="error" />
          </template>
        </v-promised>
      </b-tab-item>

      <b-tab-item :label="$t('views.personProfile.publications')" value="publications">
        <v-promised
          :promise="userPublications"
          v-if="!addAcademicProfile && !createPublication && editPublication == null"
        >
          <template #pending>
            <b-loading :can-cancel="false"></b-loading>
          </template>
          <template v-slot="data">
            <div class="columns is-centered">
              <div class="column is-9">
                <div class="pt-4 pb-6">
                  <div class="buttons">
                    <b-button
                      aria-label="add-publication"
                      type="is-primary"
                      @click="createPublication = true"
                    >
                      {{ $t('views.personProfile.addPublicationButton') }}
                    </b-button>
                    <b-button
                      aria-label="add-academic-profile"
                      type="is-primary"
                      @click="addAcademicProfile = true"
                    >
                      {{ $t('views.personProfile.addAcademicProfileButton') }}
                    </b-button>
                  </div>
                </div>
                <div v-if="data.length > 0">
                  <b-table
                    :data="data"
                    :paginated="true"
                    :per-page="perPage"
                    :current-page.sync="currentPage"
                    :pagination-simple="false"
                    pagination-position="bottom"
                    aria-next-label="Next page"
                    aria-previous-label="Previous page"
                    aria-page-label="Page"
                    aria-current-label="Current page"
                    default-sort="role_type"
                    :show-header="false"
                    class="publications-table"
                  >
                    <b-table-column field="id" v-slot="props">
                      <div class="columns">
                        <div class="column is-1">
                          <b-icon icon="file-document-outline" />
                        </div>
                        <div class="column is-7">
                          <strong>{{ props.row.title }}</strong>
                          <p>
                            <span v-html="authorsString(props.row.authors)"></span>
                            <br />
                            <span v-if="props.row.publication_date">
                              {{ props.row.publication_date }}
                            </span>
                            <span v-else>
                              {{ props.row.publication_year }}
                            </span>
                            <span v-if="props.row.source_name">
                              , {{ props.row.source_name }}
                            </span>
                          </p>
                        </div>
                        <div class="column is-3">
                          <b-button
                            aria-label="edit-publication"
                            type="is-primary"
                            @click="editPublication = props.row"
                          >
                            {{ $t('views.personProfile.editPublicationButton') }}
                          </b-button>
                        </div>
                        <div class="column is-1">
                          <div class="rounded-badge">
                            <a class="icon-button-style">
                              <span @click="deleteAlert(props.row.id)">
                                <b-icon icon="trash-can" />
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </b-table-column>
                  </b-table>
                </div>
                <div class="is-flex is-flex-direction-column py-6" v-else>
                  <div class="is-align-self-center">
                    <img id="no-division" src="@/assets/images/sin_publicaciones.png" />
                  </div>
                  <div class="is-align-self-center has-text-centered">
                    <p v-html="$t('publications.noPublications')"></p>
                  </div>
                  <div class="columns is-centered pt-4">
                    <div class="column is-5">
                      <div class="card">
                        <div class="card-content">
                          <div class="media">
                            <div class="media-left">
                              <b-icon
                                icon="information-outline"
                                size="is-large"
                                type="is-azul-imfd"
                              />
                            </div>
                            <div class="media-content">
                              {{ $t('publications.noPublicationsSuggestion') }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <template #rejected="error">
            <v-fail :error="error" />
          </template>
        </v-promised>
        <publication-create
          v-if="createPublication"
          @publicationSaved="createPublication = false"
          @refetchUserPublications="refetchUserPublications"
        />
        <publication-edit
          v-if="editPublication != null"
          :publication="editPublication"
          @publicationSaved="editPublication = null"
          @refetchUserPublications="refetchUserPublications"
        />
        <add-academic-network
          v-if="addAcademicProfile"
          @profileSaved="addAcademicProfile = false"
          :personId="person.id"
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import DivisionCard from '@/components/DivisionCard.vue'
import PersonCard from '@/components/PersonCard.vue'
import PersonEditProfile from '@/components/PersonEditProfile.vue'
import AddAcademicNetwork from '@/components/AddAcademicNetwork.vue'
import PublicationCreate from '@/components/PublicationCreate.vue'
import PublicationEdit from '@/components/PublicationEdit.vue'
import { uploadAvatar, getUserPublications } from '@/requests/people'
import { findMemberDivisions, findMemberProjects } from '@/requests/people'
import { deletePublication } from '@/requests/publications'

const { mapState } = createNamespacedHelpers('auth')

export default {
  name: 'profile',
  components: {
    DivisionCard,
    PersonCard,
    PersonEditProfile,
    AddAcademicNetwork,
    PublicationCreate,
    PublicationEdit
  },
  methods: {
    async filesChange(event) {
      const profile = await uploadAvatar(event.target.files[0])
      this.$buefy.toast.open({
        message: this.$t('views.personProfile.updateAvatar'),
        type: 'is-success',
        position: 'is-top'
      })
      this.$store.commit('auth/refreshProfile', profile)
    },
    authorsString(authors) {
      const sortAuthors = authors.slice().sort((a, b) => {
        a.position - b.position
      })
      return sortAuthors
        .map((author) =>
          author.name
            ? author.name
            : `<a href="/members/${author.id}">${author.first_name} ${author.last_name}</a>`
        )
        .join(', ')
    },
    async refetchUserPublications() {
      this.userPublications = getUserPublications(this.person.id)
    },
    async deleteAlert(articleId) {
      this.$buefy.dialog.confirm({
        message: this.$t('publications.deleteAlertMessage'),
        confirmText: this.$t('publications.deleteConfirm'),
        cancelText: this.$t('publications.deleteCancel'),
        onConfirm: async () => {
          await deletePublication(articleId)
          this.$buefy.toast.open({
            message: this.$t('publications.deleteSuccessful'),
            type: 'is-success',
            position: 'is-top'
          })
          this.refetchUserPublications()
        },
        onCancel: () => {
          this.val = []
        }
      })
    }
  },
  props: {
    initailTab: {
      type: String
    }
  },
  data() {
    return {
      memberDivisions: null,
      memberProjects: null,
      memberAcademicNetworks: null,
      userPublications: null,
      editProfile: false,
      editPhoto: false,
      addAcademicProfile: false,
      createPublication: false,
      editPublication: null,
      perPage: 7,
      currentPage: 1,
      total: 100,
      isLoading: true
    }
  },
  computed: {
    ...mapState(['person']),
    activeTab: {
      set(val) {
        const query = { ...this.$route.query }
        query.tab = val
        if (val !== 'personalInfo') {
          this.editProfile = false
        }

        this.$router.replace({ query: query })
      },
      get() {
        return this.$route.query.tab || 'personalInfo'
      }
    },
    imageUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return `${url.replace(/\/$/, '')}${this.person.avatar}?rnd=${new Date().getTime()}`
    }
  },
  mounted() {
    this.isLoading = true
    //setTimeout(3000)
    this.memberDivisions = findMemberDivisions({ userId: this.person.id })
    this.memberProjects = findMemberProjects({ userId: this.person.id })
    this.userPublications = getUserPublications(this.person.id)
    this.isLoading = false
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/stylesheets/imfd/colors.scss';

.margin {
  margin-top: 2vh;
}

.name-label {
  color: $dark;
  font-size: 21px;
  font-family: 'Roboto Slab';
}
.hover-imfd {
  :hover {
    color: $azul-imfd;
    cursor: pointer;
  }
}
.article-row {
  margin-top: 2vh;
  margin-bottom: 2vh;
}
.div-border {
  border-bottom: 1px solid rgb(201, 190, 190);
}
</style>
<style lang="scss">
.publications-table table {
  background-color: transparent;
}
</style>
