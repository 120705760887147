<template>
  <v-promised :promise="promiseAcademicNetwork">
    <template #pending>
      <b-loading :can-cancel="false"></b-loading>
    </template>
    <template v-slot="academicNetwork">
      <v-form
        :on-submit="sendForm"
        :on-submit-success="onSuccess"
        :zod-schema-validator="formSchema"
        :on-submit-error="submitError"
        ref="academicNetworkForm"
      >
        <template v-slot="{ form }">
          <div class="columns is-centered">
            <div class="column is-9">
              <div class="columns">
                <div class="column is-7 is-flex">
                  <h1 class="title has-roboto-slab pt-4">
                    {{ $t('addAcademicNetwork.title') }}
                  </h1>
                </div>
              </div>

              <div class="columns is-variable is-3">
                <div class="column is-7">
                  <div class="card">
                    <div class="card-content">
                      <div class="columns is-tablet">
                        <div class="column" data-testid="academic-network-selector">
                          <b-field
                            :label="$t('addAcademicNetwork.academicNetwork')"
                            id="academic-network-label"
                            :message="form.errors.academic_platform"
                            :type="form.types.academic_platform"
                            label-for="academic-network"
                          >
                            <b-select
                              :placeholder="
                                $t('addAcademicNetwork.academicNetworkInputPlaceholder')
                              "
                              v-model="form.values.academic_platform"
                              id="academic-network"
                            >
                              <option
                                v-for="option in academicNetwork"
                                :value="option.id"
                                :key="option.id"
                              >
                                {{ option.name }}
                              </option>
                            </b-select>
                          </b-field>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column" data-testid="academic-network-id-input">
                          <b-field
                            :label="$t('addAcademicNetwork.userId')"
                            id="platform-id"
                            :message="form.errors.platform_id"
                            :type="form.types.platform_id"
                          >
                            <b-input v-model="form.values.platform_id" />
                          </b-field>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <div class="buttons">
                            <b-button type="is-beige-light" @click="$emit('profileSaved')">{{
                              $t('personEditProfile.cancelButton')
                            }}</b-button>
                            <b-button type="is-primary" native-type="submit">
                              {{ $t('addAcademicNetwork.saveButton') }}
                            </b-button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="py-6">
                    <div class="columns">
                      <div class="column is-7">
                        <div v-if="memberAcademicNetworks && memberAcademicNetworks.length > 0">
                          <h3 class="subtitle is-5 has-roboto-slab">
                            {{ $t('views.personProfile.academicProfilesTitle') }}
                          </h3>
                          <div
                            class="article-row is-flex is-flex-direction-column is-align-items-start"
                            v-for="network of memberAcademicNetworks"
                            :key="`network${network.platform_id}`"
                          >
                            <div>
                              <strong>{{ network.academic_platform.name }}</strong
                              >:
                              {{ network.platform_id }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-5"></div>
                    </div>
                  </div>
                </div>
                <div class="column is-5">
                  <div class="card">
                    <div class="card-content">
                      <div class="media">
                        <div class="media-left">
                          <b-icon icon="information-outline" size="is-large" type="is-azul-imfd" />
                        </div>

                        <div class="media-content">
                          <p>
                            {{ $t('addAcademicNetwork.infoMessage') }}
                          </p>
                          <p>
                            <strong>{{ $t('addAcademicNetwork.infoBoldMessage') }}</strong>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </v-form>
    </template>
    <template #rejected="error">
      <v-fail :error="error" />
    </template>
  </v-promised>
</template>

<script>
import { fetchAcademicNetwork, fetchAcademicNetworks } from '@/requests/publications'
import { addAcademicProfile } from '@/requests/people'
import VForm from '@/components/VForm.vue'
import { z } from 'zod'

export default {
  components: {
    VForm
  },
  name: 'AcademicNetworkForm',
  props: {
    personId: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      promiseAcademicNetwork: null,
      memberAcademicNetworks: null,
      loadingRequest: null,
      formSchema: z.object({
        platform_id: z.string(),
        academic_platform: z.number()
      })
    }
  },
  methods: {
    async sendForm(form) {
      this.loadingRequest = this.$buefy.loading.open({
        container: this.$refs.academicNetworkForm.$el
      })
      await addAcademicProfile(form)
    },
    onSuccess() {
      this.loadingRequest.close()
      this.$buefy.toast.open({
        message: this.$t('addAcademicNetwork.successMessage'),
        type: 'is-success',
        position: 'is-top'
      })
      this.$emit('profileSaved')
    },
    submitError(err) {
      this.loadingRequest.close()
      this.$buefy.toast.open({
        message: err.message || this.$i18n.t('messageError.somethingWrong'),
        type: 'is-danger'
      })
      this.$emit('profileSaved')
    }
  },

  async mounted() {
    this.promiseAcademicNetwork = fetchAcademicNetwork()
    this.memberAcademicNetworks = await fetchAcademicNetworks({ personId: this.personId })
  }
}
</script>
