<template>
  <div>
    <router-link :to="{ name: 'division', params: { id: division.id } }">
      <div class="card card-subdivision">
        <div class="card-content">
          <img
            v-if="division.is_project"
            class="is-block"
            src="@/assets/svgs/project_divisor.svg"
            alt=""
          />
          <img v-else class="is-block" src="@/assets/svgs/divisor.svg" alt="" />
          <h5 class="title card-title">
            <router-link :to="{ name: 'division', params: { id: division.id } }">
              {{ division.name }}
            </router-link>
          </h5>
          <h5 class="title card-subtitle">
            <p v-if="!!role">
              {{ role }}
            </p>
          </h5>
          <footer class="is-inline-flex">
            <router-link
              class="member-link"
              :to="{ name: 'memberProfile', params: { id: division.active_members[0].id } }"
              v-if="division.active_members.length > 0"
            >
              <figure class="image is-32x32 is-inline-block">
                <img
                  class="is-rounded"
                  :src="`${backendUrl}${division.active_members[0].avatar}`"
                />
              </figure>
            </router-link>
            <router-link
              class="member-link"
              :to="{ name: 'memberProfile', params: { id: division.active_members[1].id } }"
              v-if="division.active_members.length > 1"
            >
              <figure class="image is-32x32 is-inline-block">
                <img
                  class="is-rounded"
                  :src="`${backendUrl}${division.active_members[1].avatar}`"
                />
              </figure>
            </router-link>
            <div
              class="rounded-badge"
              v-if="division.active_members.length > 0"
              @click.prevent.self
            >
              <a v-if="division.active_members.length > 2">
                <span @click="showModal = !showModal"
                  >+{{ division.active_members.length - 2 }}</span
                >
              </a>
              <a v-else-if="division.active_members.length > 0">
                <span @click="showModal = !showModal">
                  <b-icon icon="magnify-plus-outline"> </b-icon>
                </span>
              </a>
            </div>
          </footer>
        </div>
      </div>
    </router-link>

    <b-modal ref="membersModal" :width="480" v-model="showModal">
      <members-modal
        :division="division"
        @close="$refs.membersModal.close()"
        @deleteMember="$emit('refetchDivision')"
      />
    </b-modal>
  </div>
</template>

<script>
import MembersModal from '@/components/MembersModal.vue'

export default {
  name: 'divisionCard',
  computed: {
    backendUrl() {
      const url = process.env.VUE_APP_MEDIA_URL || 'http://localhost:8001/'
      return url.replace(/\/$/, '')
    }
  },
  components: {
    MembersModal
  },
  props: {
    division: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      showModal: false,
      isCardModalActive: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/stylesheets/imfd/colors.scss';
@import '@/assets/stylesheets/imfd/fonts.scss';

.image {
  margin: 0 1vh 0 0 !important;
}

.rounded-badge {
  background-color: $beige-lighter;
  color: $beige-lighter-invert;
  font-family: $family-serif;
  font-size: 15px;
  width: 32px;
  height: 32px;
  border-radius: 16px;
  text-align: center;

  span {
    vertical-align: sub;
  }
}
</style>
