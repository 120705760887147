import { render, staticRenderFns } from "./PersonProfile.vue?vue&type=template&id=1ec65c00&scoped=true&"
import script from "./PersonProfile.vue?vue&type=script&lang=js&"
export * from "./PersonProfile.vue?vue&type=script&lang=js&"
import style0 from "./PersonProfile.vue?vue&type=style&index=0&id=1ec65c00&scoped=true&lang=scss&"
import style1 from "./PersonProfile.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ec65c00",
  null
  
)

export default component.exports