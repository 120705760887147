<template>
  <div>
    <b-taginput
      open-on-focus
      clearable
      autocomplete
      v-model="val"
      field="name"
      :data="suggestions"
      :placeholder="$t('multiplePersonSelector.placeholder')"
      @typing="delayFetchSuggestions"
      @input="setInput"
      :id="id"
      :disabled="disabled"
      :closable="!disabled"
      aria-label="internal-authors"
    >
    </b-taginput>
  </div>
</template>

<script>
import { searchPeople } from '@/requests/people'

export default {
  props: {
    value: {
      required: false
    },
    id: {
      required: false,
      type: String
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    selectedInternalAuthors: {
      required: false,
      type: Array
    }
  },
  data() {
    return {
      suggestions: [],
      val: [],
      delayedFetchTimeout: null
    }
  },
  methods: {
    setInput(input) {
      this.$emit(
        'input',
        input.map(function (element) {
          return element.id
        })
      )
    },
    async fetchSuggestions(query) {
      const people = await searchPeople(query)
      this.suggestions = people.map((person) => {
        return { name: `${person.first_name} ${person.last_name}`, id: person.id }
      })
    },
    delayFetchSuggestions: function (query) {
      if (this.delayedFetchTimeout !== null) {
        clearTimeout(this.delayedFetchTimeout)
      }

      this.delayedFetchTimeout = setTimeout(async () => {
        await this.fetchSuggestions(query)
        this.delayedFetchTimeout = null
      }, 400)
    }
  },
  async mounted() {
    await this.fetchSuggestions(null)
    if (typeof this.value === 'number') {
      this.val = this.suggestions.filter((person) => person.id === this.value)
    } else if (typeof this.value === 'string' && this.value.length > 1) {
      this.val = [this.value]
    }

    if (this.selectedInternalAuthors != null) {
      let selection = this.selectedInternalAuthors.map((person) => {
        return { name: `${person.first_name} ${person.last_name}`, id: person.id }
      })
      this.val = selection
    }
  }
}
</script>
